import React from "react"
import styled from "styled-components"
import { getAmountWithCommas } from "../../utils/getAmountWithCommas"
import { Button } from "../core"
import { Text } from "../core/commonExports"
import { getPolicyTypeFullForm } from "../../utils/getPolicyTypeFullForm"
import { getPaymentStatus } from "../../api/selfServeAPIs"
import calculateTotalPremium from "../../utils/calculateTotalPremium"

import CheckWhite from "../../assets/images/check-white.svg"
import CircleChecked from "../../assets/images/circle-checked.svg"
import Alert from "../../assets/images/alert.svg"
import ClockWhite from "../../assets/images/clock-white.svg"
import ClockFilled from "../../assets/images/clock-filled.svg"
import { getBlogLink } from "../LeadFormSteps/thankyouScreenUtils"
import { insurerLegalNames } from "./insurerData"
import openInNewTab from "../../utils/openInNewTab"

const states = {
  PENDING: "pending",
  SUCCESS: "seccess",
  FAILURE: "failure"
}

const DataItem = ({title, data}) => {
  if (!data) return

  return (
    <DataItemContainer>
      <Text fontSize="14px" mfontSize="14px" lineHeight="22px" mlineHeight="18px" fontWeight="500" color="#635E6A" style={{margin: "0"}}>
        {title}
      </Text>
      <Text fontSize="16px" mfontSize="18px" lineHeight="22px" mlineHeight="22px" fontWeight="bold" color="#333" style={{margin: "0"}}>
        {data}
      </Text>
    </DataItemContainer>
  )
}

const PaymentsFlow = ({selectedQuote = {}, rfqId, policyDetails, onPaymentCancellation}) => {
  const [paymentState, setPaymentState] = React.useState(states.PENDING)
  const [wasInFailedState, setInFailedState] = React.useState(false)
  const [paymentDetails, setPaymentDetails] = React.useState({
		Status: '',
		Link: '',
		TxId: '',
		Insurer: '',
	})

  // const [countDown, setCountDown] = useState(60 * 5)
	const [runTimer, setRunTimer] = React.useState(true)

  const checkPaymentStatus = async () => {
		const result = await getPaymentStatus(rfqId)
		if (!result) {
			return
		}
		setPaymentDetails(result)
    if (result.Status == 'FAILED' && !wasInFailedState) {
      setPaymentState(states.FAILURE)
      setInFailedState(true)
      setRunTimer(false)
    } else if (result.Status == 'PENDING_SETTLEMENT') {
      setPaymentState(states.SUCCESS)
      setRunTimer(false)
    }
	}

  const retryPayment = () => {
		let paymentLink = paymentDetails?.Link || ''

		if (paymentLink) {
      openInNewTab(paymentLink)
			setPaymentState(states.PENDING)
			setRunTimer(true)
		}
	}

  React.useEffect(() => {
		let timerId
		if (runTimer) {
			timerId = setInterval(() => {
				checkPaymentStatus()
			}, 30000) 
    // check for payment status after every 30 seconds
		} else {
			clearInterval(timerId)
		}

		return () => clearInterval(timerId)
	}, [runTimer])

  const isPaymentSuccessful = paymentState === states.SUCCESS
  const isPaymentInProgress = paymentState === states.PENDING

  const paymentStatusTag = (
    <PaymentStatusTag success={isPaymentSuccessful} inProgress={isPaymentInProgress}>
      {isPaymentSuccessful ? (
        <> 
          <CheckWhite style={{marginRight: "0.25rem", verticalAlign: "middle"}} />
          Payment Successful
        </>
      ) : isPaymentInProgress ? (
        <>
          <ClockWhite style={{marginRight: "0.25rem", verticalAlign: "middle"}} />
          Payment in progress
        </>
      ) : "Payment Failed, please retry!"}
    </PaymentStatusTag>
  )

  let formattedPremiumAmt = ''
	const totalPremium = calculateTotalPremium(selectedQuote || {})
	if (totalPremium) {
		formattedPremiumAmt = getAmountWithCommas(totalPremium || '') 
	}

  const policyName = policyDetails?.policyName || ''
  const blogLink = getBlogLink(policyName)
  const policyType = (
    <>
      Vyapar Suraksha, <br/>
      <span style={{color: "#F78670"}}>{`${policyName} (${getPolicyTypeFullForm(policyName)})`}</span>
    </>
  )

  const insurerName = paymentDetails?.Insurer || selectedQuote?.Insurer  || ''
  const insurerLegalName = insurerLegalNames?.[insurerName] || insurerName

  const txnDetails = (
    <TnxDetailsWrapper>
      <Text fontSize="14px" mfontSize="14px" lineHeight="18px" mlineHeight="18px" fontWeight="bold" color="#333333" style={{margin: "0"}}>
        TRANSACTION DETAILS
      </Text>
      <TxnDetailsContainer>
        <DataItem title="Payment status:" data={paymentStatusTag} />
        <DataItem title="Premium amount (per annum):" data={`₹ ${formattedPremiumAmt}`} />
        {/* <DataItem title="Date of Transaction:" data="{DD/MM/YYYY}" /> */}
        <DataItem title="Transaction ID:" data={paymentDetails?.TxId || ''} />
        <DataItem title="Type of policy:" data={policyType} />
        <DataItem title="Insurer Details:" data={insurerLegalName} />
      </TxnDetailsContainer>
    </TnxDetailsWrapper>
  )

  let content = (
    <>
      <Text fontSize="26px" mfontSize="18px" lineHeight="31.47px" mlineHeight="21.78px" fontWeight="bold" color="#333" style={{textAlign: "center", margin: "0"}}>
        <ClockFilled style={{marginRight: "0.25rem", width: "26px", height: "26px", verticalAlign: "middle"}} />
        Payment in progress...
      </Text>
      <PaymentInfoContainer>
        {txnDetails}
        <Text fontSize="20px" mfontSize="16px" lineHeight="32px" mlineHeight="26px" fontWeight="600" color="#333" style={{textAlign: "center"}}>
          Please do not close this tab or exit 
        </Text>
        <PaymentCTAContainer>
          {/* TODO: add retry icon here */}
          {/* <StyledButton type="default" uppercaseLabel={false} label="Cancel Payment" style={{background: "#DD5853"}} onClick={onPaymentCancellation} /> */}
        </PaymentCTAContainer>
      </PaymentInfoContainer>
    </>
  )

  if (paymentState === states.SUCCESS) {
    content = (
      <>
        <Text fontSize="26px" mfontSize="18px" lineHeight="31.47px" mlineHeight="21.78px" fontWeight="bold" color="#333" style={{textAlign: "center", margin: "0"}}>
          <CircleChecked style={{marginRight: "0.25rem", verticalAlign: "middle"}} />
          Payment Successful
        </Text>
        <PaymentInfoContainer>
          {txnDetails}
          <Text fontSize="20px" mfontSize="16px" lineHeight="32px" mlineHeight="26px" fontWeight="600" color="#333" style={{textAlign: "center"}}>
            A verification call will be made by one of our Senior Advisors as per IRDAI Distance Marketing Guidelines
          </Text>
          <PaymentCTAContainer>
            {/* TODO: add download icon here */}
            <a href={blogLink} target="_blank">
              <StyledButton type="secondary" uppercaseLabel={false} label="Read Blogs" style={{background: "linear-gradient(180deg, #8B8ED1 -10.91%, #03045E 104.55%)"}}  />
            </a>
            {/* <StyledButton type="default" uppercaseLabel={false} label="Download Reciept"  /> */}
          </PaymentCTAContainer>
        </PaymentInfoContainer>
      </>
    )
  } else if (paymentState === states.FAILURE) {
    content = (
      <>
        <Text fontSize="26px" mfontSize="18px" lineHeight="31.47px" mlineHeight="21.78px" fontWeight="bold" color="#DD5853" style={{textAlign: "center", margin: "0"}}>
          <Alert style={{marginRight: "0.25rem", verticalAlign: "middle"}} />
          Oops! Payment Failed 
        </Text>
        <PaymentInfoContainer>
          {txnDetails}
          <Text fontSize="20px" mfontSize="16px" lineHeight="32px" mlineHeight="26px" fontWeight="600" color="rgba(74, 161, 97, 1)" style={{textAlign: "center"}}>
            No worries, Let’s try again!!
          </Text>
          <PaymentCTAContainer>
            {/* TODO: add retry icon here */}
            <StyledButton type="default" uppercaseLabel={false} label="Retry Payment" style={{background: "#75CC1F"}} onClick={retryPayment} />
          </PaymentCTAContainer>
        </PaymentInfoContainer>
      </>
    )
  }

  return (
    <div style={{padding: "1rem", paddingTop: "1.5rem"}}>
      {content}
    </div>
  )
}

const PaymentStatusTag = styled.div`
	padding: 5px 8px;
	color: ${p => (p.success || p.inProgress ? '#fff' : '#DC2F21')};
	background-color: ${p => (p.success ? '#75CC1F' : p.inProgress ? "#83A1F8" : '#F5DBDB')};
	border: 1px solid ${p => (p.success ? '#75CC1F' : p.inProgress ? "#83A1F8" : '#E29896')};
	border-radius: 6px;
	font-size: 14px;
	width: max-content;
`

const StyledButton = styled(Button)`
  background: #F78670;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: fit-content;
  height: 58px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  @media screen and (max-width: 768px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: fit-content;
    height: 48px;
    border-radius: 4px;
  }
`

const PaymentCTAContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 24px;
  @media screen and (max-width: 768px) {
    margin-top: 25px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
  }
`

const PaymentInfoContainer = styled.div`
  padding: 40px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  width: min(800px, 100%);
  margin: auto;
  margin-top: 24px;
  @media screen and (max-width: 768px) {
    padding: 16px;
    box-shadow: none;
  }
`

const TnxDetailsWrapper = styled.div`
  padding: 0 57px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const TxnDetailsContainer = styled.div`
  padding: 16px;
  border: 1px solid #C8D6DB;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 40px;
  text-align: left;
  @media screen and (max-width: 768px) {
    padding: 12px;
    margin-bottom: 25px;
  }
`

const DataItemContainer = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 8px;
  margin-bottom: 8px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }
`

export default PaymentsFlow