// Ref: https://github.com/Engaged-Insurance/client-quote/tree/master/src/utilities/getPolicyType.js

function getPolicyTypeFullForm(policyType) {
	switch (policyType) {
		case 'SFSP':
			return 'Standard Fire & Special Perils'
		case 'BLUS':
			return 'Bharat Laghu Udhyam Suraksha'
		case 'BSUS':
			return 'Bharat Sookshma Udhyam Suraksha'
		default:
			return ''
	}
}

export { getPolicyTypeFullForm }