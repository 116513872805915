import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

export const insurerImages = {
  'Reliance General Insurance': <StaticImage src="../../assets/images/insurers/RelianceGeneral.webp" alt="insurer" width={180} height={90} />,
  'Liberty General Insurance': <StaticImage src="../../assets/images/insurers/LibertyInsurance.webp" alt="insurer" width={180} height={90} />,
  'Oriental Insurance': <StaticImage src="../../assets/images/insurers/Oriental.webp" alt="insurer" width={180} height={90} />,
  'Digit Insurance': <StaticImage src="../../assets/images/insurers/DigitInsurance.webp" alt="insurer" width={180} height={90} />,
  'ICICI Lombard General Insurance': <StaticImage src="../../assets/images/insurers/ICICI.webp" alt="insurer" width={180} height={90} />,
  'Bajaj Allianz General Insurance': <StaticImage src="../../assets/images/insurers/BajajAllianz.webp" alt="insurer" width={180} height={90} />,
  'Royal Sundaram General Insurance': <StaticImage src="../../assets/images/insurers/RoyalSundaram.webp" alt="insurer" width={180} height={90} />,
  'United India Insurance': <StaticImage src="../../assets/images/insurers/UnitedIndia.webp" alt="insurer" width={180} height={90} />,
  'Tata AIG General Insurance': <StaticImage src="../../assets/images/insurers/TataAIG.webp" alt="insurer" width={180} height={90} />,
  'National Insurance': <StaticImage src="../../assets/images/insurers/NationalInsurance.webp" alt="insurer" width={180} height={90} />,
  'The New India Assurance Co. Ltd.': <StaticImage src="../../assets/images/insurers/NewIndiaAssurance.webp" alt="insurer" width={180} height={90} />,
  'Kotak General Insurance': <StaticImage src="../../assets/images/insurers/KotakGeneralInsurance.webp" alt="insurer" width={180} height={90} />,
  'Chola MS General Insurance': <StaticImage src="../../assets/images/insurers/CholaMSGeneralInsurance.webp" alt="insurer" width={180} height={90} />,
  'SBI General Insurance': <StaticImage src="../../assets/images/insurers/SBIGeneral.webp" alt="insurer" width={180} height={90} />,
  '': <StaticImage src="../../assets/images/insurers/default.webp" alt="insurer" width={180} height={90} />,
}

export const insurerLegalNames = {
	'Reliance General Insurance': 'Reliance General Insurance Company Limited',
  'Liberty General Insurance': 'Liberty General Insurance Ltd.',
  'Oriental Insurance': 'The Oriental Insurance Company Ltd.',
  'Digit Insurance': 'Go Digit General Insurance Limited',
  'ICICI Lombard General Insurance': 'ICICI Lombard General Insurance Company Limited',
  'Bajaj Allianz General Insurance': 'Bajaj Allianz General Insurance Company Ltd.',
  'Royal Sundaram General Insurance': 'Royal Sundaram General Insurance Co. Limited',
  'United India Insurance': 'United India Insurance Co. Ltd.',
  'Tata AIG General Insurance': 'Tata AIG General Insurance Company Limited',
  'National Insurance': 'National Insurance Company Limited',
  'The New India Assurance Co. Ltd.': 'The New India Assurance Co. Ltd.',
  'Kotak General Insurance': 'Kotak General Insurance Company Ltd.',
  'Chola MS General Insurance': 'Cholamandalam MS General Insurance Company Ltd.',
  'SBI General Insurance': 'SBI General Insurance Company Ltd.',
}

export const insurerMetrics = {
	// Note: ClaimsSettlementRatio is "Fire" specific
	'Liberty General Insurance': {
		SolvencyRatio: 2.18,
		ClaimsSettlementRatio: 81.53,
		EstablishedOn: '2013',
	},
	'Reliance General Insurance': {
		SolvencyRatio: 1.52,
		ClaimsSettlementRatio: 76.17,
		EstablishedOn: '2000',
	},
	'Bajaj Allianz General Insurance': {
		SolvencyRatio: 2.54,
		ClaimsSettlementRatio: 88.83,
		EstablishedOn: '2001',
	},
	'Digit Insurance': { SolvencyRatio: 3.24, ClaimsSettlementRatio: 77.17, EstablishedOn: '2016' },
	'ICICI Lombard General Insurance': {
		SolvencyRatio: 2.17,
		ClaimsSettlementRatio: 80.4,
		EstablishedOn: '2001',
	},
	'Oriental Insurance': {
		SolvencyRatio: 0.92,
		ClaimsSettlementRatio: 91.51,
		EstablishedOn: '1947',
	},
	'Royal Sundaram General Insurance': {
		SolvencyRatio: 1.69,
		ClaimsSettlementRatio: 89.29,
		EstablishedOn: '2001',
	},
	'United India Insurance': {
		SolvencyRatio: 0.3,
		ClaimsSettlementRatio: 80.7,
		EstablishedOn: '1938',
	},
	'Tata AIG General Insurance': {
		SolvencyRatio: 1.84,
		ClaimsSettlementRatio: 84.52,
		EstablishedOn: '2001',
	},
	'National Insurance': {
		SolvencyRatio: 0.02,
		ClaimsSettlementRatio: 80.59,
		EstablishedOn: '1906',
	},
	'Kotak Mahindra General': {
		SolvencyRatio: 2.13,
		ClaimsSettlementRatio: 77.67,
		EstablishedOn: '2015',
	},
	'The New India Assurance Co. Ltd.': {
		SolvencyRatio: 2.11,
		ClaimsSettlementRatio: 89.87,
		EstablishedOn: '1919',
	},
	'SBI General Insurance': {
		SolvencyRatio: 2.27,
		ClaimsSettlementRatio: 78.0,
		EstablishedOn: '2009',
	},
}

export const coverageValues = [
	{ value: 'D15', name: '15 days', months: 0.5 },
	{ value: 'M1', name: '1 month', months: 1 },
	{ value: 'M2', name: '2 months', months: 2 },
	{ value: 'M3', name: '3 months', months: 3 },
	{ value: 'M4', name: '4 months', months: 4 },
	{ value: 'M5', name: '5 months', months: 5 },
	{ value: 'M6', name: '6 months', months: 6 },
	{ value: 'M7', name: '7 months', months: 7 },
	{ value: 'M8', name: '8 months', months: 8 },
	{ value: 'M9', name: '9 months', months: 9 },
	{ value: 'M10', name: '10 months', months: 10 },
	{ value: 'M11', name: '11 months', months: 11 },
	{ value: 'M12', name: '1 year', months: 12 },
]